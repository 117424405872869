.DateRangePickerWrapper {
    background: var(--neutral-lighest);
    border: thin var(--neutral-light) solid;
    border-radius: 4px;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
    position: absolute;
    font-family: Raleway !important;
}

.DateRangePickerWrapper input, select {
    font-family: Raleway !important;
}

.CustomCalendarFooter {
    padding: 0px 16px 16px 8px;
    display: flex;
    justify-content: end;
}

.CancelButton {
    cursor: pointer;
}

.ApplyButton {
    cursor: pointer;
    color: var(--primary)
}
