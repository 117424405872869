.OuterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Container {
    width: 356px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 99;
    color: var(--neutral-lighest)
}

.MainTitle {
    margin-top: 40px;
    z-index: 2;
    text-align: center;
    position: relative;
    font-size: 36px;
    color: var(--neutral-lighest);
    font-family: Helvetica Now Display Bold;
}

.SubTitle {
    font-family: Helvetica Now Display;
    font-size: 16px;
    text-align: center;
    color: var(--neutral-lighest);
    position: relative;
    z-index: 1;
    margin-top: 4px;
    margin-bottom: 104px;
 }
