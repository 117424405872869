.react-numeric-input .input {
    width: 100%;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark);
    border-radius: 4px !important;
    padding: 16px !important;
    box-sizing: border-box;
    border: thin var(--neutral-darker) solid !important;
    height: 50px !important;
}

react-numeric-input .input:disabled {
    background: #EEEEEE;
    border: none;
}

react-numeric-input .error {
    border: solid 1px var(--danger) !important;
}

react-numeric-input .input:focus {
    outline: none !important;
    background-color: #ffffff;
    border: thin var(--neutral-darker) solid !important;
}

react-numeric-input .error:focus {
    border: solid 1px var(--danger) !important;
}

react-numeric-input .input:not(:placeholder-shown) {
    background: #FFFFFF;
    border: 1px solid var(--neutral-darker);
}

.react-numeric-input {
    width: 100%;
}

.react-numeric-input b {
    width: 33px !important;
    cursor: pointer !important;
}
