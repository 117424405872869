.ProgressBarContainer {
    z-index: 2;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 90px;
}

.Stage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.Bulb {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background: var(--neutral-lighest);
    margin-bottom: 16px;
    position: relative;
}

.BulbCurrentStage {
    outline: thick #DEDEDF solid;
    box-shadow: 0 0 100px 36px var(--neutral-light);
    transition: box-shadow 0.7s ease-in-out;
}

.Label {
    font-family: Helvetica Now Display;
    font-size: 16px;
    color: var(--neutral-lighest);
    position: absolute;
    width: 150px;
    text-align: center;
    top: 40px;
}

.Connection {
    position: absolute;
    height: 2px;
    border-radius: var(--border-radius);
    background: var(--neutral-lighest);
}
