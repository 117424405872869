.Grid {
   display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.Key {
    color: var(--neutral-lighest);
    font-size: 16px;
}

.Value {
    color: var(--neutral-light);
    font-size: 16px;
}
