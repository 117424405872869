.circle {
    display: inline-block;
    background: var(--neutral-dark);
    width: 2rem;
    height: 2rem;
    border: 2px solid #111;
    border-radius: 2.5rem;
    position: relative;
    display: flex;
    justify-content: center;
}

.circleCurrent {
    animation: animate 1s forwards;
}

.circleStatus {
    margin-top: 35px;
    text-transform: capitalize;
}

@keyframes animate {
    from {
        background: var(--neutral-dark);
    }

    to {
        background: var(--neutral-darker);
    }
}
