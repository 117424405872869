.OuterContainer {
    display: flex;
    justify-content: center;
}

.Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 793px;
    margin: auto;
}

.InputContainer {
    display: flex;
}

.Left {
    display: flex;
    width: 100%;
}

.Controls {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
