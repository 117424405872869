.settings-type-tab-container {
    max-width: 400px;
    min-width: 400px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.settings-input-section {
    display: block;
    align-items: center;
    justify-content: left;
    margin-bottom: 32px;
}

.settings-line {
    margin-top: 24px;
}
