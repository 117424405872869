.wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-content: stretch;
}

.wrapper > * {
    flex-grow: 1;
    flex-basis: 0;
    align-items: stretch;
}

.project {
    display: inline-flex;
    position: relative;
    width: 100%;
}

.project-container {
    min-width: 590px;
    max-width: 590px;
    padding-bottom: 40px;
    margin-bottom: 30px;
    background: #FFFFFF;
    box-shadow: -3px 5px 20px rgba(100, 119, 219, 0.14);
    border-radius: 4px;
}

.project-container .project-image {
    width: 100%;
    height: 240px;
    overflow: hidden;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.project-container .project-image img {
    width: 100%;
}

.project-content {
    width: 526px; /* 590 - 32px * 2 margin */
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
}

.project-header {
    vertical-align: top;
}

.project-header-left {
    width: 60%;
    float: left;
    vertical-align: top;
}

.project-header-left h2 {
    margin-top: 0px;
}

.project-header-right {
    float: right;
}

.project-bundles {
    margin-bottom: 20px;
}

.project-data {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #3C5DD7;
}

.project-description-container {
    margin-top: 40px;
}

.project-description-header {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
}

.project-description {
    margin-top: 16px;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #595959;
}

.project ul {
    padding: 0;
    list-style-position: inside;
}

.sdg {
    width: 56px;
    padding-right: 16px;
}

.project .registry-link {
    color: #595959;
}

.map-container-project {
    width: 820px;
}
