.AddRibbonOuter {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 9000;
}

.AddRibbon {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    border-radius: 8px;
    width: 100%;
    color: white;
    z-index: 9001;
}

.RibbonTitle {
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 108%;
    color: var(--neutral-lighest);
    z-index: 2;
}

.RibbonAddButtonContainer {
    position: relative;
    width: 40px;
    height: 40px;
    z-index: 9002;
}

.RibbonAddButton {
    position: absolute;
    right: 0;
    margin-left: 8px;
    box-sizing: border-box;
}

.RibbonBackground {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
}

@media (min-width: 1114px) {
    .RibbonAddButtonContainer {
        width: 100px;
        margin-left: 56px;
    }

    .RibbonTitle {
        font-size: 28px;
    }
}
