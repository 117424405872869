.side-menu {
    width: 202px; /* do not change without changing main width */
    height: 100%;
    padding-left: 20px; /* do not change without changing main width */
    background-color: #FFFFFF;
    overflow: auto;
}

.buy-offsets-btn {
    margin-top: 95px;
}

.side-menu-nav {
    margin-top: 73px;
}
