.PostDoughnutFooter {
    width: 100%;
    position: relative;
    z-index: 9999;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CostEstimate {
    width: 250px;
    position: relative;
    z-index: 1;
    font-size: 20px;
    font-family: Helvetica Now Display Bold;
    color: var(--neutral-lighest);
    text-align: center;
}

.ExploreBtn {
    margin-top: 82px;
}

.EstimateContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.FinePrint {
    font-family: Helvetica Now Display;
    font-size: 14px;
    color: var(--neutral-lighest);
}
