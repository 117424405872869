.Marker {
    height: 44px;
    width: 44px;
    border-radius: 200px !important;
    border-collapse: separate;
}

.MarkerTransparentBorder {
    height: 44px;
    width: 44px;
    background-size: cover;
    filter: opacity(50%) brightness(150%);
    border-radius: 200px !important;
    position: absolute;
    z-index: 0;
}

.MarkerInner {
    position: absolute;
    left: 2px;
    top: 2px;
    height: 40px;
    width: 40px;
    border-radius: 200px !important;
    border-collapse: separate;
    background-size: cover;
    z-index: 99;
}
