.OuterContainer {
    width: 100%;
    padding: 0 8px;
    bottom: 0;
    box-sizing: border-box;
    z-index: 999;
}

.Container {
    padding: 24px 12px;
    background-size: cover;
    border-radius: 16px 16px 0 0;

}

@media (min-width: 1114px) {
    .OuterContainer {
        display: none;
    }
}
