.Container {
    border-radius: 4px;
    width: 100%;

    display: grid;
    grid-gap: 20px;
    gap: 16px;
    grid-template-columns: repeat(auto-fill,minmax(620px, 1fr));
    justify-content: center;
}

.Container > * {
    background: var(--neutral-lighest);
    box-shadow: 0px 8px 19px -5px rgba(100, 119, 219, 0.1);
    border-radius: 4px;
    padding: 24px;
    flex: 1;
    width: initial;
}
