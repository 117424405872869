.AppAndSidebar {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.MainAppContainer {
    width: 100%;
    padding: 24px;
    background-color: var(--neutral-dark);
    overflow: auto;
    position: relative;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.MainAppContainer::-webkit-scrollbar {
    display: none;
}

.header-container {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.body {
    background-color: #F7F7F7;
    height: 100%;
    z-index: 0;
}

.content {
    min-height: 100%;
    display: block;
    width: calc(100% - 40px); /* main width - margins */
    margin-left: 20px;
    margin-top: 24px;
    margin-right: 20px;
}

.thin-content {
    min-height: 100%;
    max-width: 800px;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
}

.row {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-content: stretch;
}

.row > * {
    flex-grow: 1;
    flex-basis: 0;
    align-items: stretch;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

a {
    text-decoration: none;
    color: #3C5DD7;
    font-style: oblique;
}

a:hover {
    text-decoration: underline;
}

Link {
    color: #3C5DD7;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Raleway;
    color: var(--dark);
}

p {
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #595959;
}

.error-message {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #FE1436;
}

.section-label {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #3C5DD7;
    margin-top: 10px;
    margin-bottom: 10px;
}

.line {
    width: 100%;
    border-bottom: solid 1px #D8D8D8;
}

.code {
    font-family: "Courier New";
    color: #000000;
}

.clearfix {
    clear: both;
}

.avatar {
    border-radius: 50%;
}

pre {
    font-family: Comfortaa;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.Toastify__toast--success {
    background-color: var(--positive) !important;
    border-radius: var(--border-radius) !important;
    font-family: Raleway !important;
    color: var(--dark) !important;
    box-shadow: none;
    min-height: initial;
    width: initial !important;
    margin-bottom: 0px !important;
    font-size: 15px !important;
}

.Toastify__toast--success button {
    color: var(--dark) !important;
}

