.SuccessBanner {
    font-size: 24px;
    font-weight: bold;
}

.order-success-container {
    display: flex;
}

.spaceDown {
    margin-bottom: 8px;
}

.order-success-container h1 {
    margin-bottom: 5px;
}

.order-success-container .new-order {
    margin-top: 20px;
    margin-bottom: 10px;
}

.highlight {
    color: var(--primary)
}
