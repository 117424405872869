.Tile {
    background: #FFFFFF;
    box-shadow: -3px 5px 20px rgba(100, 119, 219, 0.14);
    border-radius: 4px;
    grid-column: span 1;
    height: 137px;
    overflow: hidden;
}

.TileLabel {
    margin-top: 31px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #3C5DD7;
}

.TileValue {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
    margin-top: 18px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 31px;
}

.Loading {
    display: initial;
}
