.OuterContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.Container {
    display: flex;
}

.NotSelected {
    min-height: 24px;
    outline: thin var(--dark) solid;
    border-radius: var(--border-radius);
}

.Btn {
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Helvetica Now Display;
    white-space: nowrap;
    padding: 8px 12px;
    font-size: 12px;
}

.Selected {
    min-height: 24px;
    border-radius: var(--border-radius);
    background: var(--dark);
    color: var(--neutral-lighest);
}
