.Grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
}

.Value {
    color: var(--primary-light);
    font-weight: bold;
}
