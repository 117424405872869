.Container {
    height: 100px;
    width: 100px;
    margin: 24px 0 12px 0;
}

.Image {
    width: 100%;
    height: auto;
}

@media (min-width: 1114px) {
    .Container {
        margin: initial;
    }
}
