.tabs {
    display: flex;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    justify-content: center;
    height: 34px;
    border-bottom: thin var(--neutral-light) solid;
    width: 100%;
    margin-bottom: 32px;
}

.tab {
    color: #595959;
    height: 100%;
}

.tab:not(:first-child) {
    margin-left: 40px;
}

.tab:hover {
    cursor: pointer;
}

.tab-active {
    border-bottom: solid 2px #3C5DD7;
    color: #3C5DD7;
    font-weight: bold;
}

.tab-content-wrapper {
    padding-bottom: 32px;
}
