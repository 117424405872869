.project-grid-container {
    margin-top: 24px;
    margin-bottom: 40px;
}

.project-grid {
    max-width: 892px;
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fit, 284px);
}
