.arrow-link {
    color: #3C5DD7;
    text-decoration: none;
}

.arrowLinkText {
    margin-right: 8px;
}

.arrow-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.container {
    display: flex;
    align-items: center;
}

.arrowLinkIcon {
    display: flex;
    align-items: center;
}
