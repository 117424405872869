.Container {
    width: 240px;
    height: 220px;
    border-radius: 16px;
    background-size: cover;
}

.BlurContainer {
    overflow: hidden;
    position: absolute;
    display: block;
    width: 240px;
    height: 220px;
    border-radius: 16px;
}

.Content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
}

.Blur {
    height: 100%;
    width: 100%;
    background-size: cover;
    filter: blur(4px);
}

.Title {
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    letter-spacing: -0.5px;
    /* Common / White */
    color: #FFFFFF;
    margin: 16px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.CTAContainer {
    display: flex;
    justify-content: flex-end;
}

.CTA {
    margin-right: 16px;
    margin-top: 16px;
}

.Description {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 132%;
    color: var(--neutral-lighest);
    padding: 16px;
    max-height: 79px;
}

@media (min-width: 1114px) {
    .Container {
        width: 220px;
        height: 280px;
        margin: initial !important;
    }
    .Description {
        max-height: 165px;
    }
    .BlurContainer {
        width: 220px;
        height: 280px;
        margin: initial !important;
    }
}
