.loaderContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
.loader {
    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid #CAD2FF;
    border-right: 1.1em solid #CAD2FF;
    border-bottom: 1.1em solid #CAD2FF;
    border-left: 1.1em solid var(--primary);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 0.6s infinite linear;
    animation: load8 0.6s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
