.DaterPickerWrapper {
    height: 50px;
    margin-bottom: 8px;
    margin-top: -8px;
}

.MainGrid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10px;
}

.ChartContainer {
    background: var(--neutral-lighest);
    padding: 16px 16px 16px 32px;
    border-radius: 4px;
    grid-column: span 2;
    grid-row: span 2;
}
