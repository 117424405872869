.project-allocation-tile {
    background: #FFFFFF;
    border: 1px solid #6477DB;
    box-sizing: border-box;
    box-shadow: 0px 8px 19px -5px rgba(100, 119, 219, 0.1);
    border-radius: 4px;
    padding: 16px;
    cursor: pointer;
}

.project-allocation-placeholder-tile {
    padding: 16px;
}

.project-allocation-tile-header {
    display: flex;
    justify-content: space-between;
}

.project-allocation-tile-title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.project-allocation-tile-allocation {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #3C5DD7;
    min-width: 100px;
    text-align: right;
}

.project-allocation-tile-row {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-content: stretch;
}

.project-allocation-tile-row > * {
    flex-grow: 1;
    flex-basis: 0;
    align-items: stretch;
}

.project-allocation-tile-text {
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #595959;
    text-overflow: initial;
    white-space: nowrap;
    overflow: visible;
}

.project-allocation-tile-quantity-info {
    height: 15px;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
    color: #595959;
    text-align: right;
}

.project-allocation-tile-warning {
    border: thin var(--warning-dark) solid;
}

.project-allocation-tooltip {
    text-align: left;
    background-color: var(--neutral-lighest);
    border: thin var(--neutral-darker) solid;
}

.project-allocation-tooltip > * {
    font-size: 80%;
}
