.outerContainer {

}

.ChainContainer {
    display: flex;
    margin-top: 32px;
    margin-bottom: 36px;
    justify-content: center;
}

.StatusLabel {
    margin-bottom: 8px;
    font-weight: bold;
}

.container {
    width: 80%;
    border-bottom: 3px solid #111;
    height: 1rem;
    display: flex;
    justify-content: space-between;
}

.completeLabel {
    right: 34px;
}
