.Scopes {
    font-family: Helvetica Now Display;
    font-size: 16px;
    text-align: center;
    color: var(--neutral-lighest);
    position: relative;
    z-index: 1;
    margin-top: 4px;
    margin-bottom: 20px;
 }

 .Scopes th {
    padding-right: 10px;
 }
