.Container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
}

.InnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
}

.OverDescription {
    color: var(--neutral-darker);
    text-align: center;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 18px;
}

.UnderDescription {
    text-align: center;
    color: var(--neutral-darker);
}

.Controls {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 16px 0;
}

.Monkey {
    border-radius: var(--border-radius);
    margin-bottom: 8px;
    background-image: url("https://assets.lune.co/monkey.jpeg");
    height: 424px;
    width: 640px;
}

.Oops {
    font-family: Raleway;
    font-size: 112px;
    text-align: center;
    margin-bottom: 24px;
    color: var(--neutral-darker);
    font-weight: bold;
}

.Message {
    color: var(--danger);
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    max-width: 100%;
    overflow-y: visible;
}

@media (min-width: 1114px) {
    .Container {
        padding-top: 64px;
    }
}
