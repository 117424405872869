.FormContainerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    box-sizing: content-box;
    padding-top: 110px;
}

.ActivePageWrapper {
    padding: 36px;
    background: var(--neutral-lighest);
    border-radius: 18px;
    width: 458px;
    box-shadow: 0 0 80px var(--neutral-light);
    min-height: 321px;
}

.CalculatorMainTitle {
    margin-top: 40px;
    z-index: 2;
    text-align: center;
    position: relative;
    font-size: 36px;
    color: var(--neutral-lighest);
    font-family: Helvetica Now Display Bold;
}

.PageTitle {
    font-size: 24px;
    font-family: Helvetica Now Display Bold;
    margin-bottom: 36px;
    text-align: center;
}

.PageSubtitle {
    font-size: 14px;
    font-family: Helvetica Now Display;
    color: var(--dark);
    margin-top: -32px;
    margin-bottom: 24px;
    text-align: center;
}

.Buttons {
    margin-top: 52px;
}

.PositionRelative {
    position: relative;
}
