.Container {
    overflow: auto;
    cursor: pointer;
}

.Container::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.Grid {
    display: grid;
    position: relative;
    display: grid;
    justify-items: flex-end;
    grid-auto-flow: column;
    grid-gap: 16px;
    width: 100vw;
}

.Grid span:first-child {
    margin-left: 16px;
}

.Empty1PXGridCell {
    width: 1px;
}

@media (min-width: 1114px) {
    .Container {
        overflow: hidden;
    }
    .Grid {
        width: initial;
    }
    .GridTwoRowsLayout {
        grid-template-rows: repeat(2, auto);
    }
    .GridThreeRowsLayout {
        grid-template-rows: repeat(3, auto);
    }
    .GridFourRowsLayout {
        grid-template-rows: repeat(4, auto);
    }
    .Empty1PXGridCell {
        display: none;
    }
    .Grid span:first-child {
        margin-left: initial;
    }
}
