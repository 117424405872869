.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary);
    height: 24px;
    padding: 4px 0;
}

.Banner {
    color: var(--neutral-lighest);
    font-family: Raleway;
    font-weight: bold;
    margin-right: 8px;
}

.InfoIcon {
    color: rgba(255, 255, 255, 0.54);
    font-size: 16px;
}

.InfoIcon:hover {
    color: rgba(255, 255, 255, 1);
}
