.Btn {
    cursor: pointer;
    color: var(--primary);
}

.Btn:hover {
    color: var(--primary-light);
}

.Container {
    margin-left: 2px;
}
