.FullContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.OuterContainer {
    padding-top: 40px;
    display: flex;
    justify-content: center;
}

.InnerContainer {
    max-width: 328px;
}

.SubTitle {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark);
    margin-bottom: 32px;
}

.CardSection {
    margin-top: 40px;
}

.BackButtonWrapper {
    position: initial;
    margin-bottom: 24px;
}

.ButtonWrapper {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.SubmitButton {
    background: var(--dark);
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.HideFooterOnMobile {
    display: none;
}

.MarginTop {
    margin-top: 40px;
}

@media (min-width: 1114px) {
    .BackButtonWrapper {
        position: absolute;
        margin-bottom: initial;
    }
    .HideFooterOnMobile {
        display: flex;
    }
}

.Header {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: var(--dark);
    margin-top: 12px;
    margin-bottom: 12px;
}

.Right {
    float: right;
}

.Left {
    float: left;
}
