.Info {
    color: rgba(255, 255, 255, 0.54);

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.Info:hover {
    color: rgba(255, 255, 255, 1);
}

.InfoHover:hover {
    cursor: pointer;
}
