.Container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.Label {
    display: flex;
    align-items: center;
    color: var(--dark);
    white-space: nowrap;
    flex: 1;
    padding-bottom: 10px;
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

.Comp {
    flex: 4;
}

.Info {
    color: var(--dark);
    font-size: 15px;
    margin-left: 4px;
    margin-top: -10px;
}

.Info:hover {
    color: var(--primary);
}

