.ExpiryAndCvcContainer {
    position: relative;
}

.Expiry {
    float: left;
    width: 40%;
    margin-right: 10px;
}

.Csv{
    float: left;
    width: 30%;
}
