.Container {
    position: relative;
    margin-bottom: 24px;
}

.Desc {
    margin-top: 20px;
    display: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.DescPre {
    font-family: Helvetica Now Display Bold;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark);
}

.Arrow {
    position: absolute;
    right: 0;
    bottom: -100px;
    top: 25px;
    transform: scaleY(-1) rotate(
            278deg
    );
}

@media (max-width: 370px) {
    .Arrow {
        position: absolute;
        right: -30px;
        left: initial;
        top: 180px;
        bottom: initial;
        transform: scale(0.6) rotate(90deg);
    }

    .Desc {
        max-width: 270px;
    }
}

@media (max-width: 371px) {
    .Arrow {
      display: none;
    }
}

@media (min-width: 1114px) {
    .Arrow {
        right: 20px;
        bottom: -100px;
        left: initial;
        top: initial;
        transform: none;
    }
    .Container {
        margin-bottom: initial;
    }
}

.PageTitle {
    text-align: left;
}

.Desc {
    display: initial;
}
