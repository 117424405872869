.CalculatorBackButton {
    cursor: pointer;
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: thin var(--neutral-lighest) solid;
    margin-left: -1px;
    position: absolute;
    z-index: 9999;
    left: 58px;
    top: 75px;
}

.Chev {
    color: var(--neutral-lighest);
    font-size: 20px;
}
