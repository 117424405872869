.Container {
    position: relative;
    background: var(--neutral-lighest);
    border-radius: 21px;
    border: 1.4px solid var(--neutral-light);
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 118px;
    height: 44px;
    box-sizing: border-box;
    overflow: hidden;
}

.Container:active {
    transform: scale(.96);
}

.Text {
    font-family: Helvetica Now Display Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #012320;
    margin:0 18px;
    text-transform: uppercase;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently                    supported by Chrome and Opera */
}

.Circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background-color: var(--neutral-dark);
    border-radius: 0 50% 50% 0;
    margin-left: -1px;
}

.Chevron {
    font-size: 24px;
    margin-left: -1px;
}

@media (min-width: 1114px) {
    .Container:hover {
        border-color: var(--dark);
    }
}
