.Container {
    padding: 24px;
    background: var(--neutral-lighest);
    position: absolute;
    right: 32px;
    border: thin var(--neutral-light) solid;
    border-radius: 4px;
    top: 80px;
    z-index: 9999;
    text-align: center;
    min-width: 233.75px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
}

.SectionTitle {
    margin-bottom: 16px !important;
}

.ActiveAccountTitle {
   color: var(--primary);
}

.ActiveAccountSection {
    display: flex;
    flex-direction: column;
}

.ActiveAccountSection > div {
    margin-bottom: 8px;
}

.SecondaryButtonWrapper {
    margin-bottom: 16px;
}

.AccountItem {
    margin-bottom: 8px;
    color: var(--primary);
    cursor: pointer;
}

.AccountItem:hover {
    color: var(--primary-light);
}

.LayoutDivider {
    margin-top: 16px;
}
