.FooterOuter {
    z-index: 9999;
    justify-content: center;
    display: flex;
    margin: 24px 0;
    width: 100%;
}

.Footer {
    z-index: 9999;
    bottom: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PoweredBy {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.LuneLogo {
    margin-left: 8px;
    margin-bottom: 4px;
}

.PartnerLogo {
    display: none;
}

@media (min-width: 1114px) {
    .FooterOuter {
        display: flex;
    }
    .PartnerLogo {
        display: initial
    }
    .Footer {
        bottom: 50px;
        display: flex;
        justify-content: space-between;
    }
}
