.input {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark);
    border: 1px solid #595959;
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
}

.input:not([type=checkbox]) {
    width: 100%;
}

.error {
    border: solid 1px var(--danger) !important;
}

.input:focus {
    outline: none !important;
    background-color: #ffffff;
    border: 1px solid #595959 !important;
}

.error:focus {
    border: solid 1px var(--danger) !important;
}

.input:not(:placeholder-shown) {
    background: #FFFFFF;
    border: 1px solid var(--neutral-darker);
}

.input:disabled {
    background: #EEEEEE;
    border: none;
}

/* Double-sized Checkboxes */
.input[type=checkbox] {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
}
