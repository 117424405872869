.LeftContainer {
    display: none;
    flex: 1;
    background-size: cover;
    padding: 32px 24px;
    box-sizing: border-box;
}

.BackButton div {
    color: var(--neutral-lighest) !important;
}

.BackButton {
    background: none;
    border: none;
    box-shadow: 0 0 0 1px rgba(161, 149, 149, 0.3);
    color: var(--neutral-lighest) !important;
}

.BackButtonCircle {
    background-color: rgba(255,255,255,0.1);
}

@media (min-width: 1114px) {
    .LeftContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
