.BundleListContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.BundleListItem {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.ProjectMetaContainer {
    max-width: 448px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.CountryName {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--dark);
    margin-bottom: 4px;
    cursor: initial;
}

.ProjectName {
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 108%;
    letter-spacing: -0.4px;
    color: var(--dark);
    margin-bottom: 8px;
}

.ProjectDescription {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #000000;
    opacity: 0.6;
    margin-bottom: 12px;
    display: none;
}

.ProjectThumbnail {
    width: 116px;
    height: 116px;
    background-size: cover;
    border-radius: 32px;
    margin-left: 16px;
}

@media (min-width: 1114px) {
    .BundleListContainer {
        margin-bottom: initial;
    }

    .ProjectDescription {
        display: initial;
    }
    .BundleListItem {
        justify-content: space-between;
        align-items: center;
    }
}
