.Background {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    top: 0;
    left: 0;
    background-size: cover !important;
    background: #091A1B
}

.Overlay {
    position: absolute;
    width: 100%;
    box-sizing: content-box;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 1;
    background: linear-gradient(159.62deg, #000000 -6.69%, rgba(0, 0, 0, 0.05) -6.68%, #000000 75.06%);
    background-size: cover;
}

.Img {
    object-fit: cover;
}
