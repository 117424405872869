.TopLine {
    margin-bottom: 16px;
}

.CopyToClipboardButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}

.Secret {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: inherit;
}

.RotateBtn {
    white-space: nowrap;
    overflow: hidden;
}

.HeaderWithInfo {
    display: flex;
}

.Info {
    color: var(--dark);
    font-size: 12px;
    margin-left: 4px;
    margin-top: -4px;
}
