.projects {
    display: inline-flex;
    position: relative;
    width: 100%;
}

.map-container {
    width: 100%;
    height: 747px;
    border: none;
    position: relative;
    margin-left: 20px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0; /* by hand */
}
