.BreakdownOuter {
    display: flex;
    justify-content: flex-end;
}

.Breakdown {
    margin-top: 24px;
    margin-bottom: 16px;
    display: inline-block;
    text-align: end;
}

.BreakdownLine {
    margin-bottom: 8px;
}

.TopLine {
    margin-bottom: 16px;
}

.BotLine {
    margin-top: 16px;
    margin-bottom: 8px;
}

.Controls {
    display: flex;
    justify-content: space-between;
}

.PriceNum {
    color: var(--primary);
}

.QuantityNum {
    color: var(--primary);
}

.FullAmount {
    color: var(--primary);
    font-weight: bold;
}
