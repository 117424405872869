.Emails {
    height: 108px;
    border: thin var(--primary) solid;
    border-radius: 4px;
    color: var(--neutral-darker);
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
}

.Desc {
    box-sizing: border-box;
    resize: vertical;
}

.Amount {
    width: 100%;
    border: thin var(--primary) solid;
    border-radius: 4px;
    box-sizing: border-box;
}

.Submit {
    display: flex;
    justify-content: center;
}

.SubmitError {
    color: var(--danger);
}

.SmallDownMargin {
    margin-bottom: 16px;
}

.RequirePaymentCheckbox {
    width: initial;
}
