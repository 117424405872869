.BackButtonMobile {
    margin-bottom: 16px;
    z-index: 99;
}

.MainContainer {
    display: flex;
    height: 100%;
}

.ControlsContainer {
    z-index: 99;
    padding: 24px 8px 0;
}

.RightContainer {
    position: relative;
    flex: 4;
    display: flex;
    justify-content: space-between;
}

.RightContainerInner {
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: space-between;
}

.Description {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--dark);
    margin-bottom: 24px;
}

.GradientBackground {
    z-index: 0;
    overflow: hidden;
    display: none;
    min-height: 100%;
    width: 100%;
    position: absolute;
}

.BlobLowerLeft {
    filter: blur(35px);
    position: absolute;
    left: -82.69%;
    right: 111.13%;
    top: 121.93%;
    bottom: -100.33%;
    background: linear-gradient(
            188deg
            , #3C5DD7 -11.68%, #6CD7C2 51.77%, #E5E84D 105.25%);
    transform: matrix(-1.8, 1, 0.9, 0.76, 0, 0) rotate(
            171deg
    ) translateX(58px);
    border-radius: 100px;
}

.BlobUpperRight {
    filter: blur(35px);
    position: absolute;
    left: 98.13%;
    right: -101.69%;
    top: -60.33%;
    bottom: 102.93%;
    background: linear-gradient(
            193.11deg
            , #3C5DD7 -11.64%, #6CD7C2 51.32%, #E5E84D 104.38%);
    transform: matrix(-0.8, 1, 2.19, 0.5, 0, 0) rotate(
            320deg
    ) translateX(-158px);
    border-radius: 100px;
}

.SwitchContainer {
    display: flex;
    justify-content: center;
    z-index: 99;
}

.ListContainerInner {
    padding: 0 8px;
}

.FixedPosition {
    position: fixed;
}

@media (min-width: 1114px) {
    .Description {
        margin-bottom: 40px
    }
    .RightContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .RightContainerInner {
        padding: 24px 8px;
    }
    .ListContainer {
        display: flex;
        justify-content: center;
    }
    .ListContainerInner {
        width: 604px;
        padding: initial;
    }
    .GradientBackground {
        display: initial;
    }
    .LeftContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .BackButtonMobile {
        display: none;
    }
    .ControlsContainer {
        padding: 0;
    }
}
