.table {
    border-collapse: collapse;
}

.table tr {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(209, 216, 245, 0.6);
    height: 60px;
}

.table td:last-child, .table th:last-child {
    padding-right: 13px;
}

.table td, .table th {
    padding: 13px;
}

.table thead {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    text-align: left;
}

.table tbody {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #595959;
    text-align: left;
}

.table tbody tr:hover {
    background-color: #ffffff;
}
