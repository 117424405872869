.label {
    background: #EEEEEE;
    border-radius: 18px;
    display: inline-block;
}

.label-content {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #686868;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
}
