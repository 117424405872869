.Halo {
    pointer-events: none;
    position: absolute;
    width: 237px;
    height: 237px;
    z-index: 1;
    border-radius: 120px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    transition: box-shadow 2s ease-in-out;
}

.ChartOuter {
    height: 390px;
    position: relative;
}

.ChartContainer {
    position: absolute;
    width: 700px;
    height: 700px;
    position: relative;
    z-index: 99;
    overflow: visible;
    top: -130px;
}

.EmissionsBreakdownGlow {
    box-shadow: 0 0 365px -27px var(--primary);
}

.BigGlow {
    box-shadow: 0 0 365px 0px rgba(2, 151, 242, 1);
}
