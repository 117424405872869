.OffsetLink {
    display: flex;
    padding: 16px;
    background-color: var(--neutral-dark);
    border-radius: var(--border-radius);
    align-items: center;
    font-family: Raleway;
}

.Label {
    color: var(--primary-light);
    margin-right: 8px;
    font-size: 14px;
}

.Group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}
