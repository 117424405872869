:root {
    --primary: #3C5DD7;
    --primary-light: #6477DB;
    --primary-lighter: #0297F2;
    --border-radius: 4px;
    --neutral-lighest: #FFFFFF;
    --neutral-light: #D8D8D8;
    --neutral-dark: #F7F7F7;
    --neutral-alt: #E0E0E0;
    --neutral-darker: #595959;
    --positive: #63DC73;
    --dark: #00000f;
    --danger: #FF1536;
    --warning: #FBFF87;
    --warning-dark: #E6E84C
}
