.Container {
    display: flex;
    font-family: Raleway;
    border: thin var(--neutral-darker) solid;
    padding: 8px;
    border-radius: var(--border-radius);
    background-color: var(--neutral-light);
    color: var(--neutral-darker);
    font-family: Raleway;
    font-size: 14px;
}

.Url {
    padding: 8px;
}

.BtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: thin var(--neutral-darker) solid;
    padding: 8px 8px 8px 16px;
}
