.Row {
    margin-bottom: 8px;

}

.Space {
    margin-bottom: 64px;
}

.BundlesOuter {
    display: flex;
    justify-content: center;
}
