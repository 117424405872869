.Container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Raleway;
}

.InnerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.OverDescription {
    color: var(--neutral-darker);
    text-align: center;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 18px;
}

.UnderDescription {
    text-align: center;
    color: var(--neutral-darker);
}

.Controls {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.Marmot {
    border-radius: var(--border-radius);
    height: auto;
    margin-bottom: 8px;
    box-sizing: border-box;
    width: 100%;
}

.Oops {
    font-family: Raleway;
    font-size: 112px;
    text-align: center;
    margin-bottom: 24px;
    color: var(--neutral-darker);
    font-weight: bold;
}

.Message {
    color: var(--danger);
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
}

@media (max-width: 720px) {
    .Marmot {
        border-radius: 0
    }
}
