.percentage-allocator-label label {
    color: #000000 !important;
    font-weight: 500;
}

.percentage-allocator td,
.percentage-allocator th {
    padding: 5px;
}

.percentage-allocator th.first,
.percentage-allocator td.first {
    width: 100%;
}

.percentage-allocator input[type="text"] {
    text-align: right;
}

.percentage-allocator th {
    text-align: left;
}

.percentage-allocator-last-row {
    text-align: right;
    border: solid 1px red;
    border-bottom: none !important;
}

.percentage-allocator-last-row:hover {
    background-color: unset !important;
}
