.Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;
    width: 100%;
    height: 100%;
}

#root {
    background: white !important;
}

.PageTitle {
    font-size: 40px !important;
    font-family: RalewayBold;
}

.Subtitle {
    text-align: center;
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
}

.Check {
    font-size: 40px;
    margin-bottom: 16px;
}

.MapContainer {
    margin-top: 20px;
    position: relative;
    height: 80%;
    width: 100%;
}

.BigMap {
    width: 100%;
    height: 100%;
}

.GradientBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    background: linear-gradient(to bottom, white 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.Blob {
    z-index: 0;
    position: absolute;
    background: linear-gradient(193.27deg, #3C5DD7 -11.68%, #6CD7C2 41.91%, #E5E84D 105.25%);
    background-blend-mode: darken, normal, normal, normal, normal, normal;
    filter: blur(4px);
}

.HideFooterOnMobile {
    display: none;
}

.Hotspots {
    display: none;
}

@media (min-width: 1114px) {
    .MapContainer {
        width: 75%;
        margin-top: 30px;
    }
    .HideFooterOnMobile {
        display: flex;
    }
    .Blob {
        transform: scale(2.5);
    }
    .Hotspots {
        display: initial;
    }
}

