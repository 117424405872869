@import './constants.css';

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/raleway/Raleway-Regular.ttf');
}

@font-face {
    font-family: 'RalewaySemiBold';
    src: url('./fonts/raleway/Raleway-SemiBold.ttf');
}

@font-face {
    font-family: 'RalewayBold';
    src: url('./fonts/raleway/Raleway-Bold.ttf');
}

@font-face {
    font-family: 'RalewayExtraBold';
    src: url('./fonts/raleway/Raleway-ExtraBold.ttf');
}

@font-face {
    font-family: 'Comfortaa';
    src: url('./fonts/comfortaa/Comfortaa-Regular.ttf');
}

@font-face {
    font-family: 'ComfortaaBold';
    src: url('./fonts/comfortaa/Comfortaa-Bold.ttf');
}

@font-face {
    font-family: 'Helvetica Now Display';
    src: url('./fonts/helveticaNowDisplay/HelveticaNowDisplay-Regular.ttf');
}

@font-face {
    font-family: 'Helvetica Now Display Bold';
    src: url('./fonts/helveticaNowDisplay/HelveticaNowDisplay-Bold.ttf');
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helveticaNeue/HelveticaNeue.ttf');
}

html {
    scroll-behavior: smooth;
}

html, body {
    height: 100%;
    min-height: 100% !important;
    margin: 0;
    position: relative;
}

#root, .App {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

body {
    font-family: 'Comfortaa', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* react-confirm-alert creates svg when dialog box shows. It appears on the body, outside the root div.
 * Due to being outside the root, it changes the page structure. It's unused, so just don't display it.*/
.react-confirm-alert-svg {
  display: none;
}
