.BigTitle {
    font-family: RalewayExtraBold;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 88%;
    letter-spacing: -1.6px;
    color: var(--dark);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
