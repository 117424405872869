.Container {
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding-left: 16px;
}

.ContainerInner {
    display: initial;
}

.LeftSection {
    max-width: 360px;
}

.RightSection {
    margin-left: -16px;
}

.MarginTopMobile {
    margin-top: 32px;
}

.TopPartnerLogo {
    margin-bottom: 0;
}

@media (min-width: 1114px) {
    .Container {
        display: flex;
        padding: 124px 0 0;
        flex-direction: initial;
    }
    .ContainerInner {
        display: flex;
    }
    .LeftSection {
        margin-right: 24px;
    }
    .TopPartnerLogo {
        display: none;
        padding: 16px 0;
    }
    .MarginTopMobile {
        margin-top: initial;
    }
    .RightSection {
        margin-left: initial;
    }
}
