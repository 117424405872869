.Layout {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
}

.LayoutContainer {
    margin-bottom: 0px;
    flex: 1;
}

.NoMargin {
    margin-top: 32px;
    margin-bottom: 0px;
}
