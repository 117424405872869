.Container {
    display: grid;
    gap: 32px 24px;
    grid-template-columns: repeat( auto-fit, 345px );
}

.Cell {
    width: 345px;
    height: 300px;
    position: relative;
    cursor: pointer;
    border-radius: 12px;
}

.Overlay {
    background: linear-gradient(190.98deg, #000000 -2.77%, rgba(0, 0, 0, 0.05) -2.76%, #000000 103.56%);
    position: absolute;
    z-index: 1;
    border-radius: 12px;
    width: 345px;
    height: 300px;
}

.CellImg {
    object-fit: cover;
    border-radius: 12px;
}

.Footer {
    font-size: 20px;
    font-family: Helvetica Now Display Bold;
    box-sizing: border-box;
    z-index: 1;
    color: var(--neutral-lighest);
    display: flex;
    justify-content: space-between;
    align-content: center;
    position: absolute;
    bottom: 36px;
    padding: 0 18px;
    width: 100%;
}
