.SwitchGroup {
    width: 100%;
    border-radius: 200px;
    border: 2px solid var(--dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 40px;
}

.Button {
     cursor: pointer;
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */
    width: 100%;
    text-align: center;
    border-radius: 200px 0 0 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--neutral-lighest);
}

.ActiveRight {
    background: var(--dark);
    color: var(--neutral-lighest);
    border-radius:  0 200px 200px 0;
}

.InactiveRight {
    border-radius: 0 200px 200px 0;
}

.ActiveLeft {
    background: var(--dark);
    color: var(--neutral-lighest);
}

@media (min-width: 1114px) {
    .SwitchGroup {
        margin-bottom: 24px;
        max-width: 604px;
    }
}


