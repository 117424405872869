.BundlesContainer {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(50px, 284px));
    justify-content: center;
    overflow-x: hidden;
}

.Link {
    text-decoration: none;
}
