.Btn {
    background: var(--primary-lighter);
    min-width: 300px;
    min-height: 50px;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    z-index: 0;
    white-space: nowrap;
    overflow: hidden;
    font-family: Helvetica Now Display Bold;
    padding: 16px 24px;
}

.BtnWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
}
