.Container {
    display: initial;
    width: 100%;
}

.RightContainer {
    flex: 4;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.RightContainerOuter {
    width: 100%;
}

.RightContainerInner {
    padding: 24px 8px 0 8px;
    box-sizing: content-box;
}

.RightContainerInner::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.MobileBackButton {
    margin-bottom: 24px;
}

.CountryName {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: var(--dark);
}

.ProjectName {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 108%;
    letter-spacing: -0.4px;
    color: var(--dark);
    margin-bottom: 12px;
}

.Tags {
    margin-bottom: 32px;
}

.CoverImage {
    max-width: 500px;
    height: 286px;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 32px;
    background-size: cover;
}

.Description {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 156%;
    color: var(--dark);
    margin-bottom: 40px;
}

.SectionTitle {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.1px;
    color: var(--dark);
    margin-bottom: 16px;
}

.ResultTile {
    background: #EDF3FA;
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 24px;

    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: var(--dark);
}

.MapInner {
    border-radius: 16px;
}

.MapWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 90px;
}

.NoPadding {
    padding: initial;
}

@media (min-width: 1114px) {
    .Map {
        margin-bottom: 40px;
    }
    .Container {
        display: flex;
    }
    .MobileBackButton {
        display: none;
    }
    .RightContainer {
        height: 100vh;
    }
    .RightContainerInner {
        max-width: 500px;
    }
    .RightContainerOuter {
        overflow-y: auto;
        display: flex;
        justify-content: center;
        margin-bottom: initial;
    }
}

