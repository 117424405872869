.Container {
    display: flex;
    padding: 16px 0;
    border-bottom: 2px solid var(--neutral-light);
}

.Img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-size: cover;
    margin-right: 12px;
}

.PageTitle {
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: var(--dark);
    margin-bottom: 12px;
}
