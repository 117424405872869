.SelectContainer {
    width: 150px;
}

.Select > * {
    border: none;
}

.Wrapper {
    display: flex;
    flex-direction: column;
}

.ChartControlWrapper {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
