.Container {
   margin-bottom: 24px;
}

.LayoutLabel {
   font-family: Raleway;
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   line-height: 24px;
   color: var(--primary);
   margin-bottom: 24px;
}

.InnerContainer {
   padding: 0 16px;
}
