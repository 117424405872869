.LogoSection {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    margin-top: -8px;
}

.Placeholder {
    border: thin black solid;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
}

.LogoUpload {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 16px;
}

.NameSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.NameInput {
    width: 100%;
}

.BeneficiarySection {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.BeneficiaryInput {
    width: 100%;
}

.UploadFAIcon {
    font-size: 24px;
}

.UploadDesc {
    font-size: 16px;
    font-family: Helvetica Now Display;
    color: var(--dark);
}

.SupportedFormat {
    color: var(--neutral-darker);
    font-size: 12px;
    margin: 8px 0 8px 0;
}

.UploadContent {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.CurrentLogo {
    font-family: Raleway;
    font-size: 12px;
    color: var(--neutral-darker);
    display: flex;
    justify-content: center;
    align-items: center;
}

.PartnerLogo {
    margin: 0;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Drop {
    font-family: RalewayBold;
}

.SaveBtn {
    margin-left: 16px;
}

.Info {
    color: var(--primary);
    font-size: 14px;
    margin-left: 4px;
}
