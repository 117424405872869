.UseLogo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.UseLogoCheckboxWrapper {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CurrentLogoPointer {
    display: flex;
    align-items: center;
}

.CurrentLogo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: RalewayBold;
}

.Arrow {
    margin: 0 16px;
}

.PartnerLogo {
    margin: 0 !important;
    height: initial !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Update {
    height: 100%;
    font-family: Helvetica Now Display;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 8px;
}

.NoLogoOuter {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoLogo {
    display: flex;
    font-family: RalewayBold;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 16px;
}
