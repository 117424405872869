@keyframes loading {
    from {background-color: var(--neutral-darker);}
    to {background-color: var(--neutral-dark);}
}

.BundleContainer {
    width: 284px;
    height: 420px;
    border-radius: var(--border-radius);
    background-size: cover;
    color: var(--neutral-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    animation-duration: 6s;
    animation-name: loading;
    animation-iteration-count: infinite
}

.BundleMeta {
    padding: 32px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.BundleTitle {
    cursor: pointer;
    font-size: 2rem;
    font-weight: bolder;
    padding: 8px 32px;
}

.BundleOffsetType,
.BundlePrice {
    cursor: pointer;
    font-weight: bold;
    padding: 8px 0;
}
