.project-tile {
    background-color: #ffffff;
    width: 284px;
    height: 420px;
    border-radius: var(--border-radius);
}

.project-tile-image {
    height: 189px;
    overflow: hidden;
    height: 189px;
    width: 284px;
    background-size: contain;
    overflow: hidden;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.project-tile-content {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 199px; /* 420px - 189px - 2 * 16px */
}

.project-tile-row {
    display: inline-block;
}

.project-tile .first-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
}

.project-tile .country {
    width: 150px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #3C5DD7;
}

.project-tile-name {
    margin-top: 4px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
}

.project-tile-label {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 10px;
}

.project-tile-body {
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #595959;
}

.project-tile-link {
    margin-top: 16px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3C5DD7;
    position: absolute;
    bottom: 0px;
}
