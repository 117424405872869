.btn {
    border-radius: 100pt;
    font-family: Raleway;
    line-height: 20px;
    font-size: 15px;
    font-weight: 500;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 39px;
    padding-right: 39px;
}

.btn-small {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.primary-btn {
    border: none;
    background-color: #3C5DD7;
    color: #ffffff;
}

.secondary-btn {
    border: solid 2px #000000;
    background-color: #ffffff;
    color: #000000;
}

.btn:hover {
    cursor: pointer;
}

.btn:active {
    outline: none;
}

.primary-btn:active {
    background-color: #6477DB;
    border: none;
}

.secondary-btn:active {
    background-color: var(--neutral-light);
}

.btn:focus {
    outline: 0;
}

.btn:disabled {
    background-color: #eeeeee;
    cursor: default;
}
