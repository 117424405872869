.Header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 189px;
    margin-bottom: 20px;
    border-radius: var(--border-radius);
    background-size: cover;
    background-position: center;
    color: var(--neutral-dark);
    font-size: 2rem;
    min-width: 588px;
    padding: 0 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: visible;
    box-sizing: border-box;
}

.Container {
    padding-bottom: 32px;
}

.Price {
    margin-top: 16px;
    font-size: 1.5rem;
}

.CTA {
    margin-top: 16px;
    background-color: var(--neutral-dark);
    font-weight: bold;
    color: black;
}

.ProjectsContainer {
    display: inline-flex;
    position: relative;
    width: 100%;
}

.MapContainer {
    width: 100%;
    height: 747px;
    border: none;
    position: relative;
    margin-left: 20px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0; /* by hand */
}
