.orders-table {
    display: flex;
    flex-direction: column;
    font-family: Raleway;
}

.load-more {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orders-table-description-col {
    width: 450px;
}

.order-table-row:hover {
    background-color: #ffffff;
    cursor: pointer;
}
