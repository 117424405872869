.Container {
    padding: 9px;
    background: var(--neutral-lighest);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Container:active {
    transform: scale(.96);
}

.Text {
    display: none;
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #012320;
    margin:0 9px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.Inner {
    border: 1.4px solid #012320;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #012320;
}

.Plus {
    font-size: 12px;
    font-weight: 600;
}

@media (min-width: 1114px) {
    .Container:hover {
        width: initial;
        border-radius: 200px;
    }
    .Container:hover > .Text {
        display: initial;
    }
}
