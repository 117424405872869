.Wrapper {
    position: absolute;
    z-index: 9999;
    font-family: Raleway;

}

.InnerWrapper {
    box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
}

.DateRangePicker {
    font-family: Helvetica Now Display !important;
    box-shadow: -3px 5px 20px rgba(100, 119, 219, 0.14) !important;
   border-radius: var(--border-radius);
    padding: 8px;
}

.DatePickerButton {
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: var(--border-radius);
    border: thin var(--neutral-light) solid;
    display: flex;
    padding: 12px 16px;
    background: var(--neutral-dark);
    cursor: pointer;
}

.Selected {
    color: var(--primary);
}

.Option {
    cursor: pointer;
    padding: 8px;
}

.Options {
    padding: 8px;
    background: var(--neutral-lighest);
    right: 32px;
    border: thin var(--neutral-light) solid;
    border-radius: 4px;
    top: 80px;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
    margin-top: 8px;
}

.Chevron {
    background: none !important;
}

.MarginTop {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
}
