.Header {
    height: 76px;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    background: white;
}

.HeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px 0 32px;
    height: 76px;
    z-index: 9999;
}

.header-gradient {
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.Username {
    margin-right: 16px;
}

.HeaderLeft {
    height: 100%;
    display: flex;
    align-items: center;
}

.HeaderRight {
    display: flex;
}

.BalanceContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Raleway;
    padding: 16px;
    margin-top: 16px;
}

.BalanceTitle {
    color: var(--primary);
}

.BalanceNumber {
    font-weight: bold;
}

.AccountButton {
    border-radius: var(--border-radius);
    border: thin var(--neutral-light) solid;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    background: var(--neutral-dark);
}

.AccountButton:hover {
    background: var(--neutral-light);
    cursor: pointer;
}

.HeaderRight {
    margin-left: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AvatarContainer {
    width: 40px;
    height: 40px;
}

.AvatarContainer img {
   height: 100%;
    width: 100%;
    border-radius: 50%;
}

.Chevron {
    background: none !important;
}
