.order-type-selector {
    border-bottom: solid 1px #D8D8D8;
}

.order-type-tab-container {
    max-width: 400px;
    min-width: 400px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.order-input-group {
    margin-left: 16px;
}

.order-input-section .input-label {
    min-width: 110px;
}
.order-input-section h2,
.order-input-section p {
    padding: 0px;
    margin: 0px;
}

.order-input-section {
    display: flex;
    align-items: center;
    justify-content: left;
}

.order-input-section label {
    margin-right: 40px;
}

.order-input-section input:not([type=checkbox]) {
    width: 250px;
}

.order-line {
    margin-top: 24px;
}
