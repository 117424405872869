.internalContainer {
    margin-left: -12px;
    margin-top: -34px;
}
.tooltipInternal {
    padding: 8px;
    padding: 8px;
    border-radius: var(--border-radius);
    z-index: 9999;
    display: inline-block;
    background-color: var(--neutral-dark);
    margin-top: 36px;
    border-radius: var(--border-radius);
}

.pin2 {
    position: absolute;
    border-radius: 50%;
    border: 8px solid var(--primary);
    width: 8px;
    height: 8px;
}

.pin2::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    border-top: 17px solid var(--primary);
}

.pin2:hover {
   cursor: pointer;
    border-color: var(--primary-light)
}

.pin2:hover:after {
    cursor: pointer;
    border-top-color: var(--primary-light)
}

.tooltipCountry {
    color: var(--primary);
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
}

.tooltipTitle {
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    color: var(--dark);
    font-size: 1rem;
    margin-bottom: 8px;

    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
}

.arrowContainer {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3C5DD7;
    bottom: 0px;
}
