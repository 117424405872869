.nav-item {
    height: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #595959;
}

.nav-item a {
    color: #595959;
}

.nav-item a:hover {
    text-decoration: none;
    color: #3C5DD7 !important;
}

.nav-item:hover {
    cursor: pointer !important;
    color: #3C5DD7 !important;
    background-color: #f7f7f7;
}

.nav-text {
    margin-left: 17px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.nav-item .active {
    color: #3C5DD7;
}

.nav-icon-container {
    width: 20px;
    position: relative;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
}

.nav-active {
    position: absolute;
    right: 0px;
    width: 4px;
    height: 100%;
    background-color: #3C5DD7;
}
