.EditSelectionBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Chevron {
    margin-left: 8px;
    background: none !important;
}
