.Container {
    border: thin var(--neutral-darker) solid;
    border-radius: 4px;
    color: var(--dark);
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
}

.Error {
    border: solid 1px var(--danger);
}
