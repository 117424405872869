.ProjectType {
    background: linear-gradient(0deg, rgba(99, 220, 116, 0.08), rgba(99, 220, 116, 0.08)), #FFFFFF;
    border: 1px solid rgba(107, 215, 195, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Helvetica Now Display;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 10px;
    text-transform: uppercase;
    display: inline-block;
    width: fit-content;
    cursor: initial;
}
