.Container {
    box-sizing: border-box;
    flex: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.Map {
    border-radius: 16px;
}

@media (min-width: 1114px) {
    .Container {
        padding: 0 48px;
        position: initial;
    }
}
