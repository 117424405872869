.select {
    overflow: visible;
    color: var(--dark);
    width: 100%;
}

.select > div:first-of-type {
    border: thin var(--neutral-darker) solid;
    overflow: visible;
    min-height: 50px;
    padding-left: 8px;
}

.error {
    border: solid 1px var(--danger) !important;
    border-radius: 4px;
}

.error > div:first-of-type {
    border: none;
    border-radius: 4px;
}

.DropdownListItem {
    white-space: nowrap;
}



