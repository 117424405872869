.Container {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
}

.ChildOuter {
    height: 100%;
}

.ChildWrapper {
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.BlobContainer {
    overflow: hidden;
    position: absolute;
}

.Blob1 {
    filter: blur(40px);
    z-index: 0;
    position: absolute;
    left: 9.87%;
    right: 13.26%;
    top: -98.33%;
    bottom: 115.98%;
    background: linear-gradient(193.11deg, #3C5DD7 -11.64%, #6CD7C2 51.32%, #E5E84D 104.38%);
    transform: rotate(35.96deg);
    border-radius: 100px;
}

.Blob2 {
    filter: blur(40px);
    z-index: 0;
    position: absolute;
    left: 89.72%;
    right: -59.81%;
    top: 56.67%;
    bottom: -38.37%;
    background: linear-gradient(193.27deg, #3C5DD7 -11.68%, #6CD7C2 51.77%, #E5E84D 105.25%);
    transform: rotate(35.96deg);
    border-radius: 50px;
}

@media (min-width: 1114px) {
    .ChildWrapper {
        height: 100%;
    }
}

@media (max-width: 1113px) and (orientation: landscape) {
    .BlobContainer {
        display: none;
    }
}
