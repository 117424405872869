.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.Amount {
    font-family: Helvetica Now Display Bold;
    font-size: 54.8571px;
    color: var(--neutral-lighest);
    line-height: 1;
}

.CO2 {
    font-family: Helvetica Now Display;
    font-size: 24px;
    color: var(--neutral-alt);
}
