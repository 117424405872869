.Emails {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.EditSelectionBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Chevron {
    margin-left: 8px;
}

.Row {
    cursor: pointer;
}
